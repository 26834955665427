
import { defineComponent, ref, reactive, toRefs, onMounted, computed } from 'vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import CreateProject from '@/views/projectManger/components/createProject.vue'
import { PROJECT_STATE, ROLE_STATE, MAJOR_STATE } from '@/hooks/config'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { getProjectList, getProjecState, getAccount, deleteProject } from '@/api/index'
import { showConfirm, setPage } from '@/hooks/common-hooks'
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '项目列表'
  },
  {
    id: 2,
    btnName: '新建项目'
  }
]
const columns = [
  {
    title: '项目编号',
    dataIndex: 'project_num',
    key: 'project_num',
    align: 'center'
  },
  {
    title: '项目名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '项目类型',
    dataIndex: 'type_name',
    key: 'type_name',
    slots: { customRender: 'type' }
  },
  {
    title: '项目状态',
    dataIndex: 'state_name',
    key: 'state_name'
  },
  {
    title: '项目计划周期',
    dataIndex: 'plan_strat_time',
    key: 'plan_strat_time',
    slots: { customRender: 'range' }
  },
  // {
  //   title: '最近操作时间',
  //   dataIndex: 'modify_time',
  //   key: 'modify_time'
  // },
  // {
  //   title: '备注',
  //   dataIndex: 'remarks',
  //   key: 'remarks'
  // },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  name: 'ProjectManger',
  components: {
    mangerPageHeader,
    CreateProject
  },
  setup () {
    const router = useRouter()
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination },
      type: null,
      search: '',
      accountInfo: {},
      isShowDetail: true, // 是否显示详情 有身份信息时显示，无时不能查看详情
      isUpdateProject: false // 是否为值班负责人 是：可创建编辑删除项目 否：无权限
    })
    const stateList = ref([])
    const getProjecStateList = () => {
      getProjecState({}).then(res => {
        stateList.value = res.data
      })
    }
    // 获取列表数据
    const getProjectListHttp = (isDelete?: boolean) => {
      getProjectList({
        type: tableData.type,
        search: tableData.search,
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      }).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          getProjectListHttp()
        }
      })
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(getProjectListHttp)
    // 翻页
    const changePage = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      getProjectListHttp()
    }
    // 跳转编辑页面
    const goEidt = (id: number) => {
      router.push({ name: 'createProject', params: { id } })
    }
    // 跳转详情页面
    const goDetail = (id: number) => {
      router.push({ name: 'projectDetail', params: { id } })
    }
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      getProjectListHttp()
    }
    const changeSelect = (value: any) => {
      tableData.type = value
      getProjectListHttp()
    }
    const getAccountInfo = () => {
      getAccount({}).then(res => {
        if (res.data) {
          tableData.accountInfo = res.data
          sessionStorage.setItem('accountInfo', JSON.stringify(tableData.accountInfo))
          // console.log(ROLE_STATE[res.data.role_id])
          tableData.isUpdateProject = ROLE_STATE[res.data.role_id] === '负责人' && MAJOR_STATE[res.data.major_id] === '值班负责'
        } else {
          tableData.isShowDetail = false
        }
      })
    }
    // 删除项目
    const deleteProjectHttp = (id: number) => {
      showConfirm({
        content: '此操作不可逆，确定删除该项目吗?',
        callBack: () => {
          deleteProject({
            id
          }).then(() => {
            message.success('删除成功')
            getProjectListHttp()
          })
        }
      })
    }
    onMounted(() => {
      getAccountInfo()
      getProjecStateList()
      getProjectListHttp()
    })
    return {
      btnList,
      btnIndex,
      changeIndex,
      ...toRefs(tableData),
      stateList,
      PROJECT_STATE,
      goEidt,
      goDetail,
      changePage,
      fetchSearch,
      changeSelect,
      deleteProjectHttp
    }
  }
})
